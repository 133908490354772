<template>
  <div class="PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('userInfo.default[0]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
      <div>
      <van-form class="mt15">
      <van-cell
        size="large"
        :title="$t('userInfo.default[1]')"
        center
        @click="showHeader = true"
      >
        <div class="userFace">
          <img :src="`./static/head/${UserInfo.header}`" />
        </div>
      </van-cell>
<!--      <van-cell-->
<!--              size="large"-->
<!--              :title="$t('userInfo.default[15]')"-->
<!--              center-->
<!--              :value="UserInfo.realname"-->
<!--      />-->
      <van-cell
              size="large"
              :title="$t('userInfo.default[2]')"
              center
              :value="UserInfo.username ? UserInfo.username : $t('userInfo.default[8]')"
              :is-link="UserInfo.username ? false : true"
      />
      <van-cell
              size="large"
              :title="$t('userInfo.default[3]')"
              center
              :value="UserInfo.username ? UserInfo.username : $t('userInfo.default[8]')"
              :is-link="UserInfo.username ? false : true"
      />
<!--      <van-cell-->
<!--              size="large"-->
<!--              :title="$t('userInfo.default[16]')"-->
<!--              center-->
<!--              :value="UserInfo.idcode"-->
<!--              :value-class="['']"-->
<!--      />-->
      <van-cell
              size="large"
              :title="$t('userInfo.default[6]')"
              center
              :value="$t('userInfo.default[8]')"
              is-link
              @click="showPassWord = true"
      />
      </van-form></div>
      <div style="text-align: center;margin-top: 20px">
        <p class="btn" @click="setUserInfo">{{ $t('userInfo.label[6]') }}</p>
      </div>
    </div>
    <van-action-sheet
      v-model="showHeader"
      :title="$t('userInfo.default[9]')"
      close-on-popstate
    >
      <van-radio-group v-model="radioHeader">
        <van-grid clickable icon-size="45" column-num="5">
          <van-grid-item
            :icon="`./static/head/head_${item}.png`"
            v-for="item in 40"
            :key="item"
            @click="selectHeader(item)"
          />
        </van-grid>
      </van-radio-group>
    </van-action-sheet>
    <van-action-sheet
      v-model="showPassWord"
      :title="$t('userInfo.default[10]')"
      close-on-popstate
    >
      <van-field
        v-model.trim="postData.o_password"
        type="password"
        :label="$t('userInfo.label[0]')"
        :placeholder="$t('userInfo.placeholder[0]')"
        clearable
        size="large"
      />
      <van-field
        v-model.trim="postData.n_password"
        type="password"
        :label="$t('userInfo.label[1]')"
        :placeholder="$t('userInfo.placeholder[1]')"
        clearable
        size="large"
      />
      <van-field
        v-model.trim="postData.r_password"
        type="password"
        :label="$t('userInfo.label[2]')"
        :placeholder="$t('userInfo.placeholder[2]')"
        clearable
        size="large"
      />
      <div style="padding: 16px">
          <p class="btn" @click="setpwd">{{ $t("userInfo.default[12]") }}</p>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
export default {
  name: "Info",
  components: {},
  props: [],
  data() {
    return {
      showHeader: false,
      showPassWord: false,
      showPayWord: false,
      radioHeader: "",
      postData: {},
    };
  },
  computed: {},
  watch: {},
  created() {
    this.postData.header = this.UserInfo.header;
    this.$Model.GetUserInfo();
    console.log(this.UserInfo);
  },
  mounted() {},
  activated() {},
  destroyed() {},
  methods: {
    selectHeader(index) {
      this.postData.header = `head_${index}.png`;
      this.UserInfo.header  = `head_${index}.png`;
      if (this.showHeader) {
        this.showHeader = false;
      }
    },
    setpwd(){
      if (this.showPassWord) {
        this.showPassWord = false;
      }
    },
    setUserInfo() {
      debugger
      this.$Model.SetUserInfo(this.postData, (data) => {
        if (data.code == 1) {
          if (this.showHeader) {
            this.showHeader = false;
          }
          if (this.showPassWord) {
            this.showPassWord = false;
          }
          if (this.showPayWord) {
            this.showPayWord = false;
          }
          this.postData = {};
        }
      });
    },
    clearCache() {
      // localStorage.clear();
      // this.$Model.GetBackData();
      // this.$router.push("/login");
      this.$Model.Logout()
    },
  },
};
</script>
<style scoped>
  .PageBox {
    color: #000;
    background: #121617;
  }

  .van-nav-bar {
    background: #121617;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#fff;
  }
  .van-nav-bar>>>.van-nav-bar__arrow {
    color:#fff;
  }

.van-cell__left-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.van-icon__image {
  width: 100%;
  height: 100%;
}
.van-cell__title {
  font-size: 14px;
  color: #000;
}
.ScrollBox {
  padding: 0 0px;
  color: #cccccc;
}

.ScrollBox >>> .van-cell {
  border-bottom: 1px solid #d9d9d9;

  background: #ffffff;
}
.ScrollBox >>> .van-cell::after {
  display: none;
}
.ScrollBox >>> .van-cell__title {
  font-size: 14px;
  font-family: Alibaba;
  font-weight: normal;
  color: #000000;
}

  .ScrollBox >>> .van-cell {
  background-color: #191C23;
}

  .ScrollBox >>> .van-cell__title {
    color:#d7d7d7;
  }

.ScrollBox >>> .van-cell__value {
  display: flex;
  color:#fff;
  justify-content: flex-end;
}
.ScrollBox >>> .userFace {
  width: 75px;
  height: 75px;
  background: #eff0f2;
  border-radius: 50%;
  padding: 6px;
  overflow: hidden;
}
.ScrollBox >>> .userFace img {
  width: 100%;
}
.ScrollBox >>> .isTrue {
  color: #333;
  padding-right: 20px;
}
.van-cell:nth-child(4) >>> .van-cell__left-icon img {
  transform: scale(1.1);
}
.ScrollBox >>> .van-cell__value {
  font-size: 15px;
}

  .btn {
    width: 85%;
    padding: 10px 50px;
    border-radius: 15px;
    background-color: #0076FA;
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin: 15px 30px 30px;
  }
</style>